<template>
  <div>
    <!-- <div class="bj"></div> -->
    <el-image :src="src" style="width:100% ;height:380px" fit="cover"></el-image>
    <div class="test">
      <ul>
        <li @click="ezx">
          <div class="big">
            <div class="tip">
              {{ '行业资讯' }}
              <div>
                <span class="number">{{ totalData.articleNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
          </div>
        </li>
        <li @click="epdf">
          <div class="big">
            <div class="tip">
              {{ '新闻' }}
              <div>
                <span class="number">{{ totalData.pdfNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
          </div>
        </li>
        <li @click="eactivity">
          <div class="big">
            <div class="tip">
              {{ '活动预告' }}
              <div>
                <span class="number">{{ totalData.activityNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
          </div>
        </li>
        <li @click="ewx">
          <div class="big" style="display: flex;justify-content: center;align-items: center;">
            <div class="sty">
              {{ '用户总数' }}
              <div>
                <span class="number">{{ totalData.userNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="sty">
              {{ '今日新增' }}
              <div>
                <span class="number">{{ totalData.TodayUserAddNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '行业资讯分享' }}
              <div>
                <span class="number">{{ totalData.pdfShareNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="big">
            <div class="tip">
              {{ '新闻分享' }}
              <div>
                <span class="number">{{ totalData.articleNum }}</span>
                <span class="tiao">条</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getOverviewData } from '../../request/http'
export default {
  data() {
    return {
      totalData: [],//统计信息
      src: require('../../assets/songzi.png')
    }
  },
  created() {
    this.getOverviewData()
  },
  methods: {
    //跳转微信
    ewx() {
      this.$router.push({
        path: '/rotationChart'
      })
    },

    //跳转资讯
    ezx() {
      this.$router.push({
        path: '/aboutus'
      })
    },
    //跳转文档
    epdf() {
      this.$router.push({
        path: '/pdfClassify'
      })
    },
    //跳转活动
    eactivity() {
      this.$router.push({
        path: '/activity'
      })
    },

    async getOverviewData() {
      const { data } = await getOverviewData()
      this.totalData = data

      console.log(this.totalData);
    }
  }
}
</script>

<style  leng="sass"  scoped>
.big {
  display: inline-block;
  width: 371px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
}

ul {
  text-decoration: none;
}

.test ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-decoration: none;
  text-align: center;
  margin: 0 30px;
}

.test ul li {
  margin: 30px auto;
  height: 200px;
  text-align: center;

  border-radius: 8px;
  list-style: none;
  width: 371px;
}

li:nth-child(1) {
  background: #f5fbff;
}

li:nth-child(2) {
  background: #f9f5ff;
}

li:nth-child(3) {
  background: #fff3f3;
}

li:nth-child(4) {
  background: #f5fffc;
}

li:nth-child(5) {
  background: #fffcf5;
}

li:nth-child(6) {
  background: rgba(126, 211, 33, 0.05);
}

.test ul:after {
  content: '';
  height: 0;
  width: 32%;
}

.tip {
  text-align: center;
  margin: 57px 130px 115px 130px;
  width: 120px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}

.sty {
  font-size: 20px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}

.number {
  display: inline-block;
  margin: 10px 10px 0 8px;
  width: 18px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

.tiao {
  display: inline-block;
  margin-left: 8px;
  width: 18px;
  height: 25px;
  font-size: 18px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

.bj {
  width: 100% !important;
  height: 380px;
  background: url('../../assets/songzi.png') no-repeat center !important;
}

.minecard {
  box-sizing: border-box;
  width: 320px;
  height: 115px !important;
  text-align: center;
}

.minecard_item_title {
  color: #909399;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.minecard_item_text {
  padding-top: 10px;
  color: #303133;
  font-size: 33px;
  line-height: 1;
  font-weight: 700;
}

.minecard .el-card__body {
  box-sizing: border-box;
  height: 100%;
}


.el-divider--vertical {
  display: inline-block;
  width: 2px;
  height: 2em;
  margin: 0 15px;
  vertical-align: middle;
  position: relative;
}
</style>

